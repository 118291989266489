<template>
  <div class="usa-footer usa-footer--slim">
    <div class="usa-footer__primary-section">
      <div class="usa-footer__primary-container grid-row">
        <div class="mobile-lg:grid-col-8">
          <p class="font-sans-3xs">
            This tool was built by NASA using the <a
              class="text-primary-dark"
              href="https://sam.gov/content/home"
          >SAM.gov</a> Entity Management API.<br>
            We encourage input! Please provide feedback and suggestions to
            <a
              class="text-primary-dark"
              href="mailto:gsa_smartpay@gsa.gov"
            >gsa_smartpay@gsa.gov</a>.
            <br>
          </p>
          <p class="font-sans-3xs">
            <a
              class="text-primary-dark"
              href="https://sam.gov/content/about/disclaimers"
            >Limitation on Permissible Use of Dun & Bradstreet, Inc. (D&B)
              Data</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>